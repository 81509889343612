.banner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cta-buttons {
    display: flex;
    gap: 10px;
    margin: 20px;
}

.purchase-link,
.review-link {
    background-color: rgba(62, 78, 66, 1);
    color: white;
    font-size: 0.75rem;
    box-shadow: 0 1px 3px rgba(62, 78, 66, .12), 0 3px 6px rgba(62, 78, 66, .16), 0 5px 12px rgba(62, 78, 66, .2);
    padding: 0.3125rem 0.625rem;
    border-radius: 0.5rem;
    font-weight: 400;
    text-decoration: none;
}

.purchase-link:hover,
.review-link:hover {
    opacity: 0.8;
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .cta-buttons {
        margin: 2rem;
        gap: 3.125rem;
    }

    .purchase-link,
    .review-link {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1025px) {
    .cta-buttons {
        justify-content: space-around;
        margin: 2rem;
        gap: 21.875rem;
    }

    .purchase-link,
    .review-link {
        font-size: 1.5rem;
    }
}