.aboutAuthor {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    padding: 1rem;
}

.carousel-container {    
    position: relative;
    width: auto;
    height: 100%;
    margin: 0 auto;
}

.slides {
    display: flex;
    position: relative;
    z-index: 1;
}

.slide {
    min-width: 250px;
    height: 250px;
    background-position: center;
    background-size: cover;
    border-radius: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 1s;
}

.slide:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.btn-slide {
    cursor: pointer;
    position: absolute;
    top: 50%;
    padding: 16px;
    width: 10px;
    margin-top: -22px;
    color: #FFF;
    font-weight: bold;
    font-size: 18px;
    user-select: none;
    z-index: 99;
}

.prev {
    left: 0;
    border-radius: 0 3px 3px 0;
}

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}
.bio {
    display: flex;
    align-items: center;
    padding: 1rem;
}
.aInfo {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.6;
    /* max-width: 75%; */
    /* height: auto; */
    text-align: left;
    color: rgba(101, 101, 68, 1);
    /* margin: 1rem; */
    border-radius: 8px;
    cursor: default;
}

.author {
    font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .aboutAuthor {
        flex-direction: row;
        justify-content: center;
    }

    .slide {
        min-width: 400px;
        height: 300px;
    }

    .aInfo {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1025px){
    .aboutAuthor {
        flex-direction: row;
        justify-content: center;
    }

    .slides {
        padding: 4rem;
    }

    .slide {
        min-width: 600px;
        height: 500px;
    }
    .bio {
        min-width: 50%;
        overflow-wrap: break-word;
    }

    .aInfo {
        font-size: 1.5rem;
    }
}