.user-component-container {
    display: flex;
    justify-content: center;
    margin-bottom: 68px;
}

.stats-card {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
}

.stats-card:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.stat-item {
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: space-around;
    text-align: center;
    padding: 25px 0;
    transition: background-color 0.3s ease;
    height: 100%; 
}

.stat-item p {
    color: #555;
    font-size: 1.1em;
}

.stat-item h5 {
    color: rgba(62, 78, 66, 1);
    font-size: 1.5em;
    font-weight: bold;
}

.fa-dice, .fa-hat-wizard, .fa-puzzle-piece {
    font-size: 35px;
    margin-bottom: 15px;
    color: rgba(62, 78, 66, 1);
    transition: transform 0.3s ease;
}

.stat-item:hover .fa-dice,
.stat-item:hover .fa-hat-wizard,
.stat-item:hover .fa-puzzle-piece {
    transform: scale(1.1);
    color: green;
}

/* Grid layout for stats */
.stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

@media (max-width: 768px) {
    .stats-grid {
        grid-template-columns: 1fr;
    }
}

