.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(62, 78, 66, .1);
    padding: 1rem;
    margin-right: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.16), 0 5px 12px rgba(0, 0, 0, 0.2);
}

.navbar .navbar-brand {
    color: rgba(62, 78, 66, 1);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    margin-right: 0;
    padding: 0;
}

.navbar .navbar-brand .text-em {
    font-size: 1.25rem;
}

.navbar .btn,
.navbar .nav-link {
    background-color: transparent;
    border: none;
    color: rgba(62, 78, 66, 1);
    /* color: white; */
    padding: 0.5rem 1rem;
    border-radius: 0;
    font-size: 0.75rem;
    padding-right: 0;
}

.navbar .btn:hover,
.navbar .nav-link:hover {
    color: rgba(62, 78, 66, 0.75);
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.1);
}

#dropdown-split-basic {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.dropdown-menu.show {
    max-height: 10px;
    overflow-y: auto;
    min-width: auto;
    margin-top: 0;
    padding-bottom: 1.5rem;
    border-radius: 0;
}

.navbar .dropdown-menu.show .dropdown-item {
    padding: 0 1rem;
}

.navbar-toggler {
    border-color: rgba(62, 78, 66, 1);
    padding: 0.1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.navbar-toggler-icon {
    /* filter: invert(100%); */
    width: 1.3em;
    height: 1.3em;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler:focus-visible {
    outline: none;
}

@media screen and (min-width: 768px) {
    .navbar {
        padding: 1.25rem;
    }

    .navbar .navbar-brand {
        /* letter-spacing: .25rem; */
        font-size: 1.5rem;
    }

    .navbar .navbar-brand .text-em {
        font-size: 2rem;
    }

    .navbar-toggler-icon {
        font-size: 2rem;
    }

    .navbar .nav-link,
    .navbar .btn,
    #dropdown-split-basic {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1024px) {
    .navbar {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 1rem 2rem;
    }

    .navbar .navbar-brand {
        flex-grow: 1;
    }

    .navbar .navigation-btns {
        flex-grow: 2;
        justify-content: right;
    }

    .navbar .nav-link,     
    .navbar .btn,
    #dropdown-split-basic  {
        font-size: 1.25rem;
    }

    .navbar .btnSplit {
        margin: 0;
    }

    .dropdown-menu.show {
        right: 0;
        left: auto;
        padding-bottom: 1.75rem;
    }
}