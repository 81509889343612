.riddle-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: rgba(62, 78, 66, 1);
    font-size: 2rem;
}

.riddle-image-link {
    position: relative;
    display: block;
    padding: 0;
    margin-bottom: 10px;
    transform: scale(0.8);
    transition: transform 0.3s ease-in-out;
    box-shadow: 1px 1px 5px 1px gray;
}

.riddle-image-link:hover {
    transform: scale(1.2);
    border: black solid 1px;
    box-shadow: 2px 2px 10px 2px black;
}

.riddle-id-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.2rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
}

.riddle-image-link:hover .riddle-id-overlay {
    font-size: 1.5rem;
}

.status-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 15px;
    z-index: 10;
    color: #fff;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.8),
        0 0 10px rgba(0, 0, 0, 0.9),
        0 0 14px rgba(0, 0, 0, 0.9);
    transform: scale(1.1);
}

.bs-check-circle {
    color: limegreen;
    background-color: rgba(0, 0, 0, 0.5);
}

.bs-x-circle {
    color: tomato;
    background-color: rgba(0, 0, 0, 0.5);
}

.bs-hourglass {
    color: gold;
    background-color: rgba(0, 0, 0, 0.5);
}

.bs-question-circle {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
}