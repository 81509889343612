.edit-component-container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-section {
    background-color: #f4f4f4;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-section:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.un-profile .data-un {
    font-weight: 700;
    color: rgba(62, 78, 66, 1);
    font-size: 1.2em;
}

h3, h4 {
    margin-bottom: 15px;
    color: #333;
    transition: color 0.3s ease;
}

h3:hover, h4:hover {
    color: #388e3c;
}

/* Adding a simple animation to the loading text */
@keyframes loading {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
}

.loading-text {
    animation: loading 1.5s infinite;
}

