.leaderboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /* background-color: rgba(62, 78, 66, .1); */
    border-radius: 8px;
    margin: 2rem;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 1);
}

.lb-title {
    color: rgba(62, 78, 66, 1);
    margin: 1rem;
    /* background-color: rgba(62, 78, 66, .25); */
    padding: 0.25rem;
    border-radius: 8px;
    /* box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1); */
    font-size: 2rem;
    text-align: center;

}

.lb-table {
    width: 100%;
    margin-top: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-collapse: collapse;
}

.tr-content {
    text-align: center;
    padding: 2rem;
    background-color: white;
    color: rgba(62, 78, 66, 1);
}

.tr-content th{
    font-weight: 600;
}

.lb-body .lb-tr:nth-child(odd) {    
    background-color: rgba(62, 78, 66, 0.75);
    color: white;
}

.lb-body .lb-tr:nth-child(even) {    
    background-color: white;
    color: rgba(62, 78, 66, 1);
}

.lb-tr {    
    background-color: #f4f4f8;
    text-align: center;
}

.lb-tr td {
    font-weight: 400;
}

.banner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cta-button {
    display: flex;
    gap: 15px;
}

.lb-review-link {
    color: rgba(62, 78, 66, 1);
    background-color: rgba(62, 78, 66, .1);
    border: 1px solid rgba(62, 78, 66, 1);
    box-shadow: 0 1px 3px rgba(62, 78, 66, .12), 0 3px 6px rgba(62, 78, 66, .16), 0 5px 12px rgba(62, 78, 66, .2);
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 0.75rem;
    font-weight: 400;
    text-decoration: none;
}

.lb-review-link:hover {
    opacity: 0.8;
}

@media screen and (min-width: 1024px) {
    .leaderboard-container {
        margin: 2rem 10rem;
    }

    .lb-title {
        font-size: 3rem;
    }

    .lb-table {
        width: 80%;
    }

    .tr-content {
        padding: 2rem 5rem;
    }
}


@media screen and (min-width: 1025px) {
.lb-review-link {
        font-size: 1.5rem;
    }
}