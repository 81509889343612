.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -3px 6px rgba(0, 0, 0, 0.16), 0 -5px 12px rgba(0, 0, 0, 0.2);
  margin-top: auto;
  padding: 1rem;
  background-color: rgba(62, 78, 66, .1);
}

.footer-info {
  display: flex;
  flex-direction: column;
}

.quick-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-left,
.footer-right {
  display: flex;
  flex-direction: column;
}

.cr {
  font-size: .65rem;
  text-align: left;
  text-decoration: none;
  margin: 0;
  color: rgba(62, 78, 66, 1);
}

.f-contact {
  font-size: .65rem;
  font-weight: 200;
  color: rgba(62, 78, 66, 1);
  text-decoration: none;
  margin: 0;
}

.sitemap,
.q-links {
  font-size: .75rem;
  font-weight: 400;
  color: rgba(62, 78, 66, 1);
}

@media screen and (min-width: 768px) {

  .cr,
  .f-contact {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .footer-container {
    padding: 2rem;
  }

  .sitemap,
  .q-links {
    font-size: 1.25rem;
  }
}