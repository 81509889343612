.homeContent-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
}

.book-cover {
    max-width: 75%;
    height: auto;
    border-radius: 8px;
    position: relative;
    box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 5px 12px rgba(0, 0, 0, 0.2);
}

.pHome {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.6;
    max-width: 75%;
    height: auto;
    text-align: left;
    color: rgba(101, 101, 68, 1);
    margin: 1rem;
    border-radius: 8px;
    cursor: default;
    box-sizing: border-box;
    overflow: hidden;
}

.headings {
    font-weight: 600;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #555;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .pHome {
        max-width: 600px;
        font-size: 1.5rem;
    }

    .book-cover {
        max-width: 600px;
    }
}

@media screen and (min-width: 1025px) {
    .homeContent-wrapper {
        flex-direction: row;
        padding-bottom: 4rem;
    }

    .book-cover {
        max-width: 650px;
        height: 650px;
        border-radius: 8px;
        box-shadow:
            -5px 0 12px rgba(0, 0, 0, 0.12),
            0 -5px 12px rgba(0, 0, 0, 0.16),
            0 5px 12px rgba(0, 0, 0, 0.2),
            inset 5px 0 2100px -3px rgba(0, 0, 0, 0.2);
        border-left: 2mm ridge;
    }
    
    .pHome {
        height: 650px;
        max-width: 650px;
        font-size: 1.5rem;
        margin: 0;
        padding: 0.5rem;
        border-right: 2mm ridge;
        box-sizing: border-box;
        overflow: hidden;
        box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.12),
        0 3px 6px rgba(0, 0, 0, 0.16),
        0 5px 12px rgba(0, 0, 0, 0.2);
    }

    .headings {
        font-weight: 500;
    }

    .pHome-text {
        margin: 1rem;
    }
}