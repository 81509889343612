.riddle-stats-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* max-width: 100%; */
    /* margin: 20px auto;
    max-height: 500px;
    overflow-y: auto; */
}

.riddle-stats-container h1 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    color: green;
}

.riddle-stats-container > div {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.riddle-stats-container h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: green;
}

.riddle-stats-container p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #666;
}

.riddle-stats-container p span {
    color: green;
    font-weight: bold;
}

@media (min-width: 768px) {
    .riddle-stats-container h1 {
        margin-top: 0;
        font-size: 32px;
    }

    .riddle-stats-container h2 {
        font-size: 24px;
    }
}