.id-diff {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(62, 78, 66, 1)
}

.specific-riddle-difficulty,
.specific-riddle-id {
    width: 30%;
    font-size: 1rem;
}

.specific-riddle-points {
    font-size: .9em;
}

.specific-riddle-difficulty {
    text-align: right;
}

.specific-riddle-container {
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid rgba(62, 78, 66, 1);
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}

.specific-riddle-container .specific-riddle-image {
    max-width: 100%;
    max-height: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 1rem;
    border: 1mm ridge rgba(0, 0, 0, 1);
}

.specific-riddle-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.specific-riddle-form input[type="text"]:focus {
    box-shadow: 0 0 10px rgba(62, 78, 66, 1);
}

.answer-input-group {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.notification-container {
    align-content: center;
    color: rgba(62, 78, 66, 1);
    border-radius: 8px;
    padding: 0 1rem;
    background-color: rgba(62, 78, 66, .1);
    box-shadow:
        inset 3px 3px 6px rgba(48, 87, 85, 0.5),
        inset -3px -3px 6px rgba(48, 87, 85, 0.5);
}

.correct-style {
    background-color: green;
    color: #fff;
    padding: 0.5rem;
    border-radius: 8px;
    border: none;
    animation: pulse 1s ease-in-out infinite;
}

.incorrect-style {
    background-color: red;
    color: #fff;
    padding: 0.5rem;
    border-radius: 8px;
    border: none;
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
}

.hint-confirmation {
    margin-top: 10px;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #888;
}

.hint-btn {
    font-size: 16px;
    background-color: goldenrod;
    border: none;
    transition: background-color 0.3s, transform 0.3s;
}

.hint-btn:hover {
    background-color: gold;
}

.hint-btn:active {
    background-color: goldenrod !important;
    transform: scale(0.95);
}

.hint-btn:focus {
    background-color: gold;
    outline: none;
}

.hint-confirmation button {
    padding: 2px 10px;
    border-radius: 8px;
}

.give-up-btn {
    border: none;
    background-color: red;
    letter-spacing: 0.25rem;
    padding: 0;
}

.answer-display {
    color: rgba(62, 78, 66, 1);
    font-weight: 800;
}

.btn-submit {
    background-color: rgba(62, 78, 66, 1);
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    letter-spacing: .25rem;
}

.btn-submit:hover {
    background-color: rgba(62, 78, 66, 0.75);
    border: none;
    animation: colorPulse 1s infinite;
}

.btn-submit:disabled {
    color: #666;
    background-color: #ccc;
    cursor: not-allowed;
}

.navigation-buttons {
    position: relative;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigation-buttons .text-left {
    text-align: left;
}

.navigation-buttons .text-right {
    text-align: right;
}

.navigation-buttons .text-left .btn-nav,
.navigation-buttons .text-right .btn-nav {
    width: 100%;
    max-width: 100px;
    display: inline;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: white;
    background-color: rgba(62, 78, 66, 1);
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.5s;
}

.text-center .rules-btn {
    font-size: 0.75rem;
    display: inline;
    padding: 5px 10px;
    margin-bottom: 10px;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: rgba(62, 78, 66, 1);
    border: 1px solid rgba(62, 78, 66, 1);
    border-radius: 8px;
    font-weight: 600;
    transition: all 0.5s;
}

.navigation-buttons .text-left .btn-nav:hover,
.navigation-buttons .text-right .btn-nav:hover {
    background-color: rgba(62, 78, 66, 0.75);
}

.navigation-buttons .text-left .btn-nav:active,
.navigation-buttons .text-right .btn-nav:active {
    background-color: rgba(62, 78, 66, 0.5);
    box-shadow: 0 5px rgba(62, 78, 66, 1);
    transform: translateY(4px);
}

.arrow-icons {
    display: inline;
    position: relative;
    transition: 0.5s;
}

.navigation-buttons .text-left .btn-nav .arrow-icons::after {
    content: '\226A';
    position: absolute;
    opacity: 0;
    top: -10%;
    left: -10px;
    right: -20px;
    transition: 0.5s;
}

.navigation-buttons .text-left .btn-nav:hover .arrow-icons {
    padding-left: 15px;
}

.navigation-buttons .text-left .btn-nav:hover .arrow-icons::after {
    opacity: 1;
    right: 0;
}

.navigation-buttons .text-right .btn-nav .arrow-icons::after {
    content: '\226B';
    position: absolute;
    opacity: 0;
    top: -10%;
    left: -10px;
    right: -20px;
    transition: 0.5s;
}

.navigation-buttons .text-right .btn-nav:hover .arrow-icons {
    padding-right: 15px;
}

.navigation-buttons .text-right .btn-nav:hover .arrow-icons::after {
    opacity: 1;
    left: 0;
}

.riddle-paragraph {
    margin-bottom: 1em;
}

/* Scoring Rules */
.rules-container {
    position: absolute;
    left: 50%;
    /* width: 100%; */
    transform: translateX(-50%);
    max-width: 200px;
    color: rgba(62, 78, 66, 1);
    background-color: #f9f9f9;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    line-height: 1;
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.rules-visible {
    display: block;
    opacity: 1;
}

.rules-title,
.rules-p,
.ast {
    margin: 10px;
}

.rules-title {
    font-size: 1.2rem;
}

.rules-p,
.ast {
    font-size: 0.75rem;
}

.ast {
    position: relative;
    align-self: center;

}

/* Animations */
@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes pulse {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

@keyframes colorPulse {

    0%,
    100% {
        background-color: rgba(62, 78, 66, 0.75);
    }

    50% {
        background-color: rgba(62, 78, 66, 0.5);
    }
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px);
    }
}

/* Media Queries */
@media(min-width: 768px) {

    .specific-riddle-difficulty,
    .specific-riddle-id {
        font-size: 1.25rem;
    }

    .specific-riddle-points {
        font-size: 1em;
    }

    .specific-riddle-container {
        max-width: 100%;
    }

    .specific-riddle-container .specific-riddle-image {
        width: auto;
        max-height: 600px;
        margin-bottom: 0;
    }

    .navigation-buttons .text-left {
        text-align: left;
    }

    .navigation-buttons .text-right {
        text-align: right;
    }

    .navigation-buttons .btn-nav {
        /* margin-top: 4rem; */
        /* padding: 5px 10px; */
        font-size: 16px;
    }

    .specific-riddle-container .answer-form {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .notification-container {
        text-align: left;
    }

    .correct-style {
        margin: 0;
    }

    /* Scoring Rules */
    .rules-container {
        max-width: 400px;
    }

    .rules-p,
    .ast {
        font-size: 1em;
    }

    .text-center .rules-btn {
        font-size: 1rem;
    }
}